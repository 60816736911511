import React, { useContext } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { Subtitle2 } from "osu-react-components";
import { AppContext } from "../../App/components";
import { ACTION_STATUS_LOADING, ACTION_STATUS_SUCCESS } from "../../util/constants";
import useStyles from "../styles/theme";

function Account(props) {
    const classes = useStyles();
    const { signIn, signOut, user } = useContext(AppContext);
    const onLogin = () => {
        signIn(true);
    }
    const onLogout = () => {
        signOut();
    }
    switch (user.status) {
        case ACTION_STATUS_SUCCESS:
            return (
                <div className={props.className}>
                    <Button color="primary" onClick={onLogout} className="d-inline-block">Log Out</Button>
                    <Subtitle2 color="gray" className="d-inline-block ml-1">{user.name}</Subtitle2>
                </div>
            );
        case ACTION_STATUS_LOADING:
            return (<CircularProgress className={`${classes.loading}${props.className ? ` ${props.className}` : ''}`} color="secondary" />);
        default:
            return (<Button className={props.className} color="primary" onClick={onLogin}>Log In</Button>);
    }
}

Account.defaultProps = {
    className: ''
};

Account.propTypes = {
    className: PropTypes.string
};

export default Account;