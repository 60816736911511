import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { API, Auth } from 'aws-amplify';
import Snackbar from '@material-ui/core/Snackbar'

import {
  OSUError,
  OSULoading,
  PaginationWrapper,
  Typography,
  Icon
} from "osu-react-components";

import Table from '../../Table/components'
import Link from "@material-ui/core/Link";
import {
  ACTION_STATUS_ERROR,
  ACTION_STATUS_LOADING,
  ACTION_STATUS_SUCCESS
} from "../../util/constants";
import { formatDateString } from "../../util/sort-formatters.js";
import "../styles/index.css";


var sortKey;
var name = "";
var id = "";
function getData(val, osuid) {
  name = val;
  id = osuid;

}

export default function Review(props) {
  const [open, setOpen] = React.useState(false);
  const [popUpBlocker, setPopUpBlockerOpen] = React.useState(false);
  if (props.status === "") {
    props.getReviewContent();
  }

  let key_val = [];
  let obj = {};

  props.data !== undefined && props.data.map(item => {
    item.documents.map(keyword => {
      obj = {
        "Period Start Date": remove_date_time(keyword["Period Start Date"]),
        "Payroll Company Name": keyword["Payroll Company Name"],
        "Gross Amount": "$" + keyword["Gross Amount"],
        "Net Amount": "$" + keyword["Net Amount"],
        "Period End Date": remove_date_time(keyword["Period End Date"]),
        "Check Date": remove_date_time(keyword["Check Date"]),
        "Check Number": keyword["Check Number"],
        "documentID": keyword["documentID"],
        "Document Link": (
          <div>
            <Link component="button" variant="body2" onClick={() => { fetchDocd(keyword['documentID'], setOpen, setPopUpBlockerOpen) }} target="_blank" download>
              View/Print
            </Link>
          </div>
        )
      }

      key_val.push(obj);
      return obj
    })

    return key_val
  });

  Auth.currentSession().then(session => {
    return new Promise((resolve, reject) =>
      setTimeout(() => {

        resolve([session.idToken.payload.given_name, session.idToken.payload.osuid])
      }, 1000))
  }
  ).then((res) => {
    getData(res[0], res[1])
  }).catch(error => { console.log(error) })

  const dataKeys = [
    { label: "Company", key: "Payroll Company Name", width: 20 },
    { label: "Period Start Date", key: "Period Start Date", sortFormatter: formatDateString, width: 14 },
    { label: "Period End Date", key: "Period End Date", sortFormatter: formatDateString, width: 14 },
    { label: "Payment Date", key: "Check Date", sortFormatter: formatDateString, sortDirection: 'desc', width: 14 },
    { label: "Gross Amount", key: "Gross Amount", width: 15 },
    { label: "Net Amount", key: "Net Amount", width: 15 },
    { label: "Document Link", key: "Document Link", width: 8 }
  ];

  const csv_data_keys = [
    { label: "Company", key: "Payroll Company Name" },
    { label: "Period Start Date", key: "Period Start Date", sortFormatter: formatDateString },
    { label: "Period End Date", key: "Period End Date", sortFormatter: formatDateString },
    { label: "Payment Date", key: "Check Date", sortFormatter: formatDateString, sortDirection: 'desc' },
    { label: "Gross Amount", key: "Gross Amount" },
    { label: "Net Amount", key: "Net Amount" }
  ];

  let sorted_data = key_val.sort(function (a, b) {
    var dateA = new Date(a["Check Date"]), dateB = new Date(b["Check Date"])
    return dateB - dateA //sort by date descending
  })

  const [dataIndex, setDataIndex] = useState(0);
  const updateDataIndex = value => {
    setDataIndex(value);
  };

  return (

    props.status === ACTION_STATUS_LOADING ?
      <div style={{ width: "auto", margin: "auto" }}>
        <div className="mytable" style={{ width: "90%", margin: "auto" }}>

          <OSULoading text="Loading Payslips" />

        </div>
        <div className="myheight" style={{ height: "250px" }}>

        </div>

        <br clear="all" />
      </div>
      :
      props.status === ACTION_STATUS_ERROR ?
        <div style={{ width: "auto", margin: "auto" }}>
          <div className="mytable" style={{ width: "100%", margin: "auto" }}>

            <OSUError
              text="Failed to retrieve content."
              small="true"
              actionText="Retry"
              onClick={() => props.getReviewContent()}
            />
          </div>
          <div className="myheight" style={{ height: "250px" }}>

          </div>
          <br clear="all" />
        </div>
        :
        key_val.length > 0 ?

          props.status === ACTION_STATUS_SUCCESS && props.data !== undefined && props.data.length > 0 && sorted_data.length > 0 && (
            <div style={{ width: "auto", margin: "auto" }}>
              <div className="mytable" style={{ width: '100%', margin: 'auto' }}>
                <Typography className="headpay" > </Typography>

                <h1>My Payslips Prior to 2021</h1>
                <p>All payslips for pay periods ending after December 2020 are available to view in <a href={process.env.REACT_APP_WORKDAY_PAYSLIPS_URL} target="_blank" rel="noopener noreferrer">Workday</a>.<br/><br/></p>

                <Snackbar open={popUpBlocker || open} aria-live="polite"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  message={popUpBlocker ? "Pop Ups Blocked...." : "Opening...."}
                  autoHideDuration={3000}
                  onClose={() => setPopUpBlockerOpen(false)}
                // other Snackbar props
                />
                <div className="download_btn">
                  {name !== "" && name !== "undefined" && <CSVLink data={sorted_data} headers={csv_data_keys} filename={name + "-" + id + "-paydocs.csv"}>
                    <Icon color="blue" className="mr-2" type="download" />Download CSV</CSVLink>}
                </div>
                <PaginationWrapper
                  persist
                  updateDataIndex={updateDataIndex}
                  dataIndex={dataIndex}
                  resultsData={{
                    shownResults: key_val.length,
                    totalResults: key_val.length
                  }}
                  showOptionalCount={true}
                >
                  <div className="table-responsive">
                    <div className="table">
                      <Table
                        id="payslipTable"
                        sortable
                        defaultSortColumn={sortKey}
                        defaultSortDirection="desc"
                        headerVariant="subtitle2"
                        rowHeight="3"
                        dataKeys={dataKeys}
                        data={key_val}
                        noDataMessage="No Payslip available"
                        caption="Payslips Prior to 2021"
                      />
                    </div>
                    <br clear="all" />
                  </div>
                </PaginationWrapper>
              </div>
            </div>
          ) : key_val.length === 0 ?
            props.status === "no data" && props.data !== undefined && (
              <div>
                <div>
                  <h4>This page is for viewing payslips issued between January 2016 and December 2020. You do not have payslips for that time period.</h4>
                  <h4>If you believe this is an error, please contact HR Connection at <a href="phoneto (614) 247-6947">(614) 247-6947</a> or <a href="email:HRConnection@osu.edu">HRConnection@osu.edu</a></h4>
                </div>
              </div>
            )
            : <OSULoading text="Loading Payslips" />
  )
}

function remove_date_time(params) {
  if(params) {
    let obj = params.split(" ");
    return obj[0];
  } else {
    return null;
  }
}

function fetchDocd(doc_id, setOpen, setPopUpBlockerOpen) {
  setOpen(true)
  Auth.currentSession()
    .then(session => {
      API.get(process.env.REACT_APP_DIGITAL_HOME_API_NAME, "/doc", { "queryStringParameters": { "id": doc_id } })
        .then(res => {
          var newWin = window.open(res.url)
          if (isPopupBlockerActivated(newWin)) {
            setPopUpBlockerOpen(true);
            setOpen(false);
          } else {
            setOpen(false)
            return res
          }
        }
        )
        .then(result => {
          return result
        })
    })
    .catch(error => {
      if (error) {
        console.log(error)
      }
    });
}

const isPopupBlockerActivated = function(popupWindow) {
  if (popupWindow) {
      if (/chrome/.test(navigator.userAgent.toLowerCase())) {
          try {
            popupWindow.focus();
          } catch (e) {
            console.log("Couldn't set focus so pop up blocked")
            return true;
          }
      } else {
          popupWindow.onload = function() {
            return (popupWindow.innerHeight > 0) === false;
          };
      }
  } else {
    console.log("Pop up blocked");
    return true;
  }
  return false;
};