import React, { useState } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import useStyles from "../styles/theme";
import { Icon } from "osu-react-components";

export default function NavLinks() {
    const [links] = useState([
        { name: "Payslips", url: "/payslip" },
        { name: "Tax Documents", url: "/taxdocument" },
        { name: "Compass", url: "/compass", external: true }
    ]);
    const onDropdownItemClick = (event) => {
        // find the NavLink and click it
        const target = event.target;
        if(target) {
            let navLink = null;
            if(target.tagName === "A") navLink = target; // NavLink from click event
            if(target.tagName === "BUTTON") { // DropdownItem from keyboard event
                if(target.firstChild && target.firstChild.tagName === "A") navLink = target.firstChild; // Navlink from DropdownItem
            }
            if(navLink) navLink.click();
        }
    };
    return (
        <div className="mr-1">
            <Hidden smDown>
                {links.map((link, index) => {
                    return (<NavLink key={index} link={link} />);
                })}
            </Hidden>
            <Hidden mdUp>
                <UncontrolledDropdown>
                    <DropdownToggle color="white" aria-controls="appNavMenu">
                        <Icon type="bars" color="gray" ariaLabel="Navigation Menu" />
                    </DropdownToggle>
                    <DropdownMenu id="appNavMenu">
                        {links.map((link, index) => {
                            return (
                                <DropdownItem key={index} onClick={onDropdownItemClick}>
                                    <NavLink link={link} />
                                </DropdownItem>
                            );
                        })}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Hidden>
        </div>
    );
}

function NavLink(props) {
    const classes = useStyles();
    const link = props.link;
    return (
        <Link component={RouterLink} to={link.url} key={link.name} variant="subtitle1" className={classes.links}>
            {link.name}
            {link.external === true &&
                <i aria-label="(link is external)" className="fas fab fa fa-external-link" style={{ color: "#0f4fc9", marginLeft: "0.2rem" }} />
            }
        </Link>
    );
}