import { isEmpty } from "lodash";
import { REVIEW_CONTENT, REVIEW_CONTENT_FILTER, REVIEW_CONTENT_STATUS } from "../actions-index";
import { ACTION_STATUS_SUCCESS } from "../util/constants";
var a = "no data"


export function reviewContent(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case REVIEW_CONTENT:
            if (payload[0].documents.length !==0){
               a =  ACTION_STATUS_SUCCESS ;
            }
            return { 

                data: payload  ,     
                status: !isEmpty(payload) ? a : state.status
            };
        case REVIEW_CONTENT_STATUS:
            return { ...state, status: payload };
        case REVIEW_CONTENT_FILTER:
            const { field, value } = payload;
            const filter = {...state.filter};
            if(isEmpty(value)) {
                delete filter[field];
            } else {
                filter[field] = value;
            }
            return { ...state, filter };
        default:
            return state;
    }
}
