export function transformUser(user = {}) {
    return {
        applicationRoles: user.applicationRoles,
        email: user.email,
        name: user.displayName || user.name,
        osuid: user.osuid
    };
}
export function transformUserId(id) {
    return id
}
