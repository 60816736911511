// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "document-link-20200221101507-hostingbucket-develop",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d13zejn0hxhmh7.cloudfront.net"
};


export default awsmobile;
