import { REVIEW_CONTENT, REVIEW_CONTENT_FILTER, REVIEW_CONTENT_STATUS } from "../actions-index";
import { API, Auth } from 'aws-amplify';
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ERROR_NO_CURRENT_USER, ACTION_STATUS_SUCCESS } from "../util/constants";
import { buildAction, redirectToSignIn } from "../util/util";


export function getReviewContent() {
    return dispatch => {
        dispatch(buildAction(REVIEW_CONTENT_STATUS, ACTION_STATUS_LOADING));
        Auth.currentSession()
            .then(() => {
                API.get(process.env.REACT_APP_DIGITAL_HOME_API_NAME, "/payslip")
                    .then(result => {
                        dispatch(buildAction(REVIEW_CONTENT_STATUS, ACTION_STATUS_SUCCESS));
                        dispatch(buildAction(REVIEW_CONTENT, result));
                        return result
                    });
            })
            .catch(error => {
                if (error === ERROR_NO_CURRENT_USER) {
                    redirectToSignIn();
                } else {
                    dispatch(buildAction(REVIEW_CONTENT_STATUS, ACTION_STATUS_ERROR));
                }
            });
    };
}


export function filterReviewContent(field, value) {
    return dispatch => dispatch(buildAction(REVIEW_CONTENT_FILTER, { field, value }));
}
