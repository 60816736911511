import { typeColor, rootDarkColor } from "../../styles/palette";

const { standard, darkMode } = typeColor

const standardPalette = {
    background: {
        default: "#ffffff"
    },
    text: {
        default: standard.default
    },
    textColor: standard.default,
    primary: {
        main: standard.action,
    },
    secondary: {
        main: standard.secondary,
    },
    textPrimary: {
        main: standard.action
    }
}

const darkModePalette = {
    background: {
        default: rootDarkColor
    },    
    textColor: darkMode.default,
    primary: {
        main: darkMode.action,
    },
    secondary: {
        main: darkMode.secondary,
    }
}

export { standardPalette, darkModePalette }