import { isEmpty } from "lodash";
import { USER, USER_STATUS,USER_ID } from "../actions-index";
import { ACTION_STATUS_SUCCESS } from "../util/constants";

export function user(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {

        case USER:
            return { 
                data: payload, 
                status: !isEmpty(payload) ? ACTION_STATUS_SUCCESS : state.status
            };
        case USER_STATUS:
            return {
                ...state,
                status: payload
            };
            case USER_ID:
                return {
                    data:payload,
                status: !isEmpty(payload) ? ACTION_STATUS_SUCCESS : state.osuid
                }
        default:
            return state;
    }
}
