export const standardColor = {
    red: "#BB0000",
    gray: "#666666",
    orange: "#C04F24",
    brown: "#453831",
    green: "#73792D",
    teal: "#1C7C89",
    blue: "#476C97",
    purple: "#5D3F82",
    pink: "#851E5E",
    darkGreen: "#0F610A"
}

export const darkModeColor = {
    red: "#DE2D1E",
    gray: "#c2c2c2",
    orange: "#E4773B",
    brown: "#9C684D",
    green: "#939C0A",
    teal: "#3FA3A6",
    blue: "#659AC7",
    purple: "#8367AC",
    pink: "#B05588",
    darkGreen: "#14870B"
}

export const typeColor = {
    standard: {
        action: "#0f4fc9",
        primary: standardColor.red,
        secondary: standardColor.gray,
        default: "#000000"
    },
    darkMode: {
        action: "#9db8db",
        primary: darkModeColor.gray,
        secondary: darkModeColor.gray,
        default: "#ffffff"
    }
}

export const rootDarkColor = "#121212"