import React, { useState } from "react";
import { API, Auth } from 'aws-amplify';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '../../Table/components';

import {
  OSUError,
  OSULoading,
  PaginationWrapper,
  Typography
} from "osu-react-components";
import {
  ACTION_STATUS_ERROR,
  ACTION_STATUS_LOADING,
  ACTION_STATUS_SUCCESS
} from "../../util/constants";
import Link from "@material-ui/core/Link";
import "../styles/index.css";
import { formatText } from "../../util/sort-formatters";

export default function Content(props) {
  const [open, setOpen] = React.useState(false);
  const [popUpBlocker, setPopUpBlockerOpen] = React.useState(false);
  if (props.status === "") props.getContent();
  let key_val = [];
  let obj = {};

  props.data.map(item => {
    item.documents.length !== 0 && item.documents.map((doc, index) => {
      let doctype;
      if (item.documenttype === "w2") {
        doctype = "W-2"
      } else {
        doctype = "W-2c"
      }

      obj = {
        "Payroll Company Name": "The Ohio State University",
        "Tax Year": doc["Tax Year"],
        "documentID": doc["documentID"],
        "Tax Form": doctype,
        "document Link": <Link
          component="button"
          variant="body2"
          onClick={() => {
            fetchDocd(doc['documentID'], setOpen, setPopUpBlockerOpen)
          }}
          target="_blank"
          download >
          View/Print
      </Link>
      }  
      key_val.push(obj)
      return obj   
    })
    return key_val
  })

  const dataKeys = [
    { label: "Tax Year", key: "Tax Year", width: 20 },
    { label: "Company", key: "Payroll Company Name", width: 40 },
    { label: "Tax Form", key: "Tax Form", sortFormatter: formatText, order: "desc", width: 20 },
    { label: "Document Link", key: "document Link", width: 20 }
  ];

  const [dataIndex, setDataIndex] = useState(0);
  const updateDataIndex = value => {
    setDataIndex(value);
  };

  return (
    props.status === ACTION_STATUS_LOADING ?
      <div>
        <OSULoading text="Loading Tax Documents" />
        <div className="myheight" style={{ height: "250px" }}>
        </div>
        <br clear="all" />
      </div>
      :
      props.status === ACTION_STATUS_ERROR ?
        <div>
          <div className="mytable" style={{ width: "100%", margin: "auto" }}>
            <OSUError
              text="Failed to retrieve content."
              small="true"
              actionText="Retry"
              onClick={() => props.getContent()}
            />
            <div className="myheight" style={{ height: "250px" }}>
            </div>
            <br clear="all" />
          </div>
        </div>
        :
        key_val.length > 0 ?
          props.status === ACTION_STATUS_SUCCESS && props.data !== undefined &&
          <div>
            <div className="mytable" style={{ margin: "auto" }}>
              <Typography className="headpay" variant="heading5"></Typography>

              <h1>My Tax Documents Prior to Tax Year 2021</h1>
              <Snackbar open={popUpBlocker || open}
                aria-live="polite"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }} 
                message={popUpBlocker ? "Pop Ups Blocked...." : "Opening...."}
                autoHideDuration={3000}
                onClose={() => setPopUpBlockerOpen(false)}
              // other Snackbar props
              />
              <div>
                <PaginationWrapper
                  persist
                  updateDataIndex={updateDataIndex}
                  dataIndex={dataIndex}
                  resultsData={{
                    shownResults: key_val.length,
                    totalResults: key_val.length
                  }}
                  showOptionalCount={true}
                >
                  <div className="table-responsive">
                    <div className="table">
                      <Table
                        id="taxDocumentTable"
                        sortable
                        defaultSortColumn={0}
                        defaultSortDirection="desc"
                        headerVariant="subtitle2"
                        rowHeight="3"
                        dataKeys={dataKeys}
                        data={key_val}
                        noDataMessage="No Tax Documents available"
                        caption="Tax Documents Prior to Tax Year 2021"
                      />
                    </div>
                  </div>
                </PaginationWrapper>
              </div>
            </div>
          </div>
          :
          key_val.length === 0 ?
            props.status === "no data" && props.data !== undefined && (
              <div>
                <div>
                  <h4>This page is for viewing payroll tax documents issued for tax years 2016 through 2020. You do not have payroll tax documents for those tax years. </h4>
                  <h4>If you believe this is an error, please contact HR Connection at <a href="phoneto (614) 247-6947">(614) 247-6947</a> or <a href="email: HRConnection@osu.edu">HRConnection@osu.edu</a></h4>
                </div>
              </div>
            )
            :
            <OSULoading text="Loading Tax Documents" />            
  );
}

function fetchDocd(doc_id, setOpen, setPopUpBlockerOpen) {
  setOpen(true)
  Auth.currentSession()
    .then(session => {
      API.get(process.env.REACT_APP_DIGITAL_HOME_API_NAME, "/doc", { "queryStringParameters": { "id": doc_id } })
        .then(res => {
          var newWin = window.open(res.url);
          if (isPopupBlockerActivated(newWin)) {
            setPopUpBlockerOpen(true);
            setOpen(false);
          } else {
            setOpen(false)
            return res
          }
        })
        .then(result => {
          return result
        })
    })
    .catch(error => {
      if (error) {
        console.log(error)
      }

    });
  }


const isPopupBlockerActivated = function(popupWindow) {
  if (popupWindow) {
      if (/chrome/.test(navigator.userAgent.toLowerCase())) {
          try {
            popupWindow.focus();
          } catch (e) {
            console.log("Couldn't set focus so pop up blocked")
            return true;
          }
      } else {
          popupWindow.onload = function() {
            return (popupWindow.innerHeight > 0) === false;
          };
      }
  } else {
    console.log("Pop up blocked");
    return true;
  }
  return false;
};