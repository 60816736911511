import React from 'react'
import '../styles/index.css';



function Footer() {
    return (
        <footer className="footer-dk-gray p-4">
            <div className="row no-row-margin">
                <div className="col-md-12 col-sm-7 d-flex">
                    <div className="">
                        <div className="col-sm-12 pb-4">
                            <a href="http://osu.edu">
                                <img className="osu-logo" src="https://cybersecurity.osu.edu/profiles/wcm_base/themes/wcm_omega/images/osu-logos/osu-footer-wordmark-dk-gray.svg"
                                    alt="The Ohio State University" />
                            </a>
                        </div>
                        <div className="col-md-12 text-center text-sm-left footer-text">
                            <p>&copy; Copyright, 2020. The Ohio State University.</p>
                            <p>This page is maintained by the Office of the Chief Information Officer</p>
                            <p><ul> <li>If you have questions regarding your pay information, please contact HR Connection:</li>  <li> <a href="phoneto 614-247-6947">Phone: (614) 247-6947</a> | </li>  <li> <a href="email: HRConnection@osu.edu">Email: HRConnection@osu.edu</a> </li> </ul> </p>
                            <p><ul> <li>If you have trouble accessing this page, please contact:</li> <li><a href="phoneto 614-688-4357 ">Phone:  614-688-4357 </a> | </li> <li> <a href="email: 8help@osu.edu">Email: 8help@osu.edu</a> </li> </ul> </p>

                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}


export default Footer

