import { Auth } from "aws-amplify";
import { USER, USER_STATUS, USER_ID } from "../actions-index";
import { transformUser, transformUserId} from "./transform";
import { ACTION_STATUS_LOADING, ACTION_STATUS_ERROR, ERROR_NO_CURRENT_USER } from "../util/constants";
import { buildAction, redirectToSignIn } from "../util/util";

export function signIn(redirect = false) {
    return dispatch => {
        dispatch(buildAction(USER_STATUS, ACTION_STATUS_LOADING));
        const validateSession = session => {
            if(session) dispatch(buildAction(USER, transformUser(session.idToken.payload)));
            return;
        };
        const savesessionId = id => {
            console.log("sessionid",id)
            if(id)  dispatch(buildAction(USER_ID, transformUserId(id)));
            return;
        };
        return Auth.currentSession()
            .then(session => validateSession(session))
            .catch(error => {
                if(redirect && error === ERROR_NO_CURRENT_USER) {
                    redirectToSignIn();
                } else {
                    setTimeout(() => {
                        Auth.currentSession()
                            .then(session => {
                                console.log(session.idToken.payload["custom:emplid"])
                                savesessionId(session.idToken.payload["custom:emplid"])
                                validateSession(session)
                            })
                            .catch(() => {
                                setTimeout(() => {
                                    Auth.currentSession()
                                        .then(session => validateSession(session))
                                        .catch(error => {
                                            console.error(error);
                                            dispatch(buildAction(USER_STATUS, ACTION_STATUS_ERROR));
                                        });
                                }, 8000);
                            });
                    }, 5000);
                }
            });
    };
}