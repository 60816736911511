import { createMuiTheme } from "@material-ui/core";
import { fonts } from "./typography";
import elevations from "./elevations";
import { mapValues } from "lodash";
import { typeColor } from "../../styles/palette";

const defaultTheme = createMuiTheme();

const navBackground = () => ({
    flexGrow: 1,
    zIndex: defaultTheme.zIndex.drawer + 1
})

const focus = {
    root: {
        "&:focus": {
            outline: "-webkit-focus-ring-color auto 5px"
        }
    }
}

const overrides = (darkMode = false, elevation = {}) => {
    const typePalette = typeColor[darkMode ? "darkMode" : "standard"]
    const shade = darkMode ? "#ffffff" : "#000000"
    return {
        MuiInputBase: {
            root: {
                color: "inherit"
            }
        },
        MuiButtonBase: focus,
        MuiPaper: {
            root: {
                color: shade
            },
            ...darkMode ? mapValues(elevations, (item => ({ ...item, boxShadow: "none" }))) : {}
        },
        MuiTypography: {
            root: {
                color: shade
            },
            colorTextSecondary: {
                color: typePalette.secondary
            }
        },
        MuiBottomNavigationAction: {
            root: {
                color: typePalette.secondary
            }
        },
        MuiSvgIcon: {
            colorPrimary: {
                fill: typePalette.action
            },
            colorSecondary: {
                fill: typePalette.secondary
            }
        },
        MuiCssBaseline: {
            "@global": {
                "@font-face": fonts
            }
        },
        MuiListSubheader: {
            root: {
                color: shade
            }
        },
        MuiAppBar: {
            root: {
                ...navBackground()
            }
        },
        MuiBottomNavigation: {
            root: {
                ...navBackground(),
                ...darkMode ? elevations[`elevation${elevation.navigation}`] : {},
                overflow: "hidden",
                position: "fixed",
                bottom: 0,
                width: "100%"
            }
        },
        MuiSkeleton: {
            root: {
                backgroundColor: `rgba(${darkMode ? `255, 255, 255` : `0, 0, 0`}, 0.1)`
            }
        }
    }
}

export default overrides