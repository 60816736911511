import { Auth } from "aws-amplify";
import { RedirectOptions } from './cookies'


export function buildAction(type, payload) {
    return { type, payload };
}

export function redirectToSignIn(redirectPath = ''){
    const { oauth } = Auth.configure();
    if(!!redirectPath || !!window.location.pathname) {
        let path = redirectPath

        if(!path) {
            path = window.location.pathname
            if(!!window.location.search) {
                path += window.location.search
            }
        }
        RedirectOptions.remove()
        RedirectOptions.set(path)
    }
    Auth.federatedSignIn({customProvider: oauth.idp})
}
