import ProximaNova from "../../fonts/ProximaNova-Reg.woff"
import ProximaNovaLight from "../../fonts/ProximaNova-Light.woff"
import ProximaNovaSbold from "../../fonts/ProximaNova-Sbold.woff"

const typography = {
    fontFamily: [
        "proximanova",
        "-apple-system",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
    ].join(",")
}

const proximanova = {
    fontFamily: "proximanova",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: [
        `local("proxima-nova")`,
        `local("ProximaNova-Reg")`,
        `url(${ProximaNova}) format("woff")`
    ].join(",")
};

const proximanovaLight = {
    fontFamily: "proximanova",
    fontStyle: "light",
    fontDisplay: "swap",
    fontWeight: 300,
    src: [
        `local("proxima-nova")`,
        `local("ProximaNova-Light")`,
        `url(${ProximaNovaLight}) format("woff")`,
    ].join(",")
};

const proximanovaSbold = {
    fontFamily: "proximanova",
    fontStyle: "semibold",
    fontDisplay: "swap",
    fontWeight: 500,
    src: [
        `local("proxima-nova")`,
        `local("ProximaNova-Sbold")`,
        `url(${ProximaNovaSbold}) format("woff")`,
    ].join(",")
};

const fonts = [proximanova, proximanovaLight, proximanovaSbold]

export { fonts, typography as default }