import React, { useEffect, useState, useContext } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import SignIn from "../../Authentication/components/SignIn";
import SignOut from "../../Authentication/components/SignOut";
import Review from "../../Payslip/containers";
import Content from "../../Taxdocument/containers";
import { APPLICATION_TITLE } from "../../util/constants";
import { RedirectOptions } from '../../util/cookies';
import { AppContext } from ".";

function Routes() {
    const history = useHistory();
    const redirectPath = RedirectOptions.get();
    const {  user = { status: '' } } = useContext(AppContext) || {}
    const [pageTitle, setPageTitle] = useState(APPLICATION_TITLE);
    const redirectExclusions = ['/signin', '/signout']
    useEffect(() => {
        document.title = pageTitle ? `${pageTitle} | ${APPLICATION_TITLE}` : APPLICATION_TITLE;
    });
    const renderPage = (pageTitle, component) => {
        setPageTitle(pageTitle);
        return component;
    }
    const validateAutoRedirect = () => user.status === 'success' && !!redirectPath && redirectPath.length > 0 && !redirectExclusions.includes(redirectPath)

    return (
        <Switch>
            {validateAutoRedirect() && <Redirect from={`/signin`} to={redirectPath} />}
            <Route exact path="/" render={() => <Redirect to="/payslip" />} />
            <Route exact path="/signin" render={() => renderPage(null, <SignIn />)} />
            <Route exact path="/signout" render={() => renderPage(null, <SignOut />)} />
            <Route exact path="/payslip" render={() => renderPage("Payslip", <Review />)} />
            <Route exact path="/taxdocument" render={() => renderPage("Taxdocument", <Content />)} />
            <Route path='/compass' component={() => {
                window.open("https://compass.osu.edu", "_blank");
                history.goBack();
                return null;
            }} />
        </Switch>
    );
}

Routes.defaultProps = {
    pathname: "/"
}

Routes.propTypes = {
    pathname: PropTypes.string.isRequired
}

export default Routes;