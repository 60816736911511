import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar as MuiAppBar, Toolbar, Typography } from "@material-ui/core";
import useStyles from "../styles/theme";
import { APPLICATION_NAME } from "../../util/constants";

const brandIcon = require("../../Common/assets/block-osu.svg");

function AppBar(props) {
    const classes = useStyles();
    const { className, determineHeight, elevation } = props;
    const ref = useRef({ current: { clientHeight: null } });
    useEffect(() => { determineHeight(ref.current.clientHeight) }, [ref, determineHeight]);
    return (
        <MuiAppBar ref={ref} elevation={elevation} className={className} position="sticky" color="inherit">
            <Toolbar className="d-flex flex-wrap">
                <Link to="/">
                    <img alt={`${APPLICATION_NAME} home`} className={classes.icon} src={brandIcon} />
                </Link>
                <Typography color="secondary" className={classes.title} noWrap>
                    {APPLICATION_NAME}
                </Typography>
                <div className="d-inline-flex">
                    {props.children}
                </div>
            </Toolbar>
        </MuiAppBar>
    );
}

AppBar.defaultProps = {
    className: '',
    determineHeight: (height) => ({ height }),
    elevation: 0
}

AppBar.propTypes = {
    className: PropTypes.string,
    determineHeight: PropTypes.func.isRequired,
    elevation: PropTypes.number.isRequired
}

export default AppBar;