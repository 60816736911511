import { isEmpty } from "lodash";
import { CONTENT, CONTENT_FILTER, CONTENT_STATUS } from "../actions-index";
import { ACTION_STATUS_SUCCESS } from "../util/constants";

var a = "no data";

export function contentContent(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case CONTENT:
            
            if (payload[0].documents.length !== 0 || payload[1].documents.length !== 0){
                a = ACTION_STATUS_SUCCESS;
            }

            return { 
                data: payload,
                filter: {},
                status: !isEmpty(payload) ? a : state.status
            };
        case CONTENT_STATUS:
            return { ...state, status: payload };
        case CONTENT_FILTER:
            const { field, value } = payload;
            const filter = {...state.filter};
            if(isEmpty(value)) {
                delete filter[field];
            } else {
                filter[field] = value;
            }
            
            return { ...state, filter };
        default:
            return state;
    }
}
