import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Typography, CircularProgress, Button } from "@material-ui/core";
import { AppContext } from "../../App/components";
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ACTION_STATUS_SUCCESS, APPLICATION_NAME } from "../../util/constants";
import { redirectToSignIn } from "../../util/util";

export default function SignIn(props) {
    const { user } = useContext(AppContext);
    switch (user.status) {
        case ACTION_STATUS_LOADING:
            return (
                <Typography className="d-flex flex-column align-items-center" component="span" variant="h5">
                    Logging in...
                    <CircularProgress className="mt-2" width={60} height={60} color="primary" />
                </Typography>
            );
        case ACTION_STATUS_ERROR:
            return (
                <Typography className="d-flex flex-column align-items-center" component="span" variant="h5">
                    There was an error logging you in.
                    <Button className="mt-2" variant="contained" color="primary" aria-label={`Retry logging in to ${APPLICATION_NAME}`} onClick={() => redirectToSignIn()}>Log In</Button>
                </Typography>
            );
        case ACTION_STATUS_SUCCESS:
            return (<Redirect to="/payslip" />);
        default:
            return null;
    }
}