import * as Cookies from "js-cookie";


export default (function () {
    function CustomAuthStorage(data) {
        this.domain = data.domain;
        this.path = data.path ? data.path : "/";
        if(Object.prototype.hasOwnProperty.call(data, "expires")) { //session cookie is default
            this.expires = data.expires;
        }
        this.secure = Object.prototype.hasOwnProperty.call(data, "secure") ? data.secure : true;
    }

    CustomAuthStorage.prototype.setItem = function setItem(key, value) {
        Cookies.set(key, value, {
          path: this.path,
          expires: this.expires,
          domain: this.domain,
          secure: this.secure
        });
        return Cookies.get(key);
    };
    
    CustomAuthStorage.prototype.getItem = function getItem(key) {
        return Cookies.get(key);
    };
    
    CustomAuthStorage.prototype.removeItem = function removeItem(key) {
        return Cookies.remove(key, {
            path: this.path,
            domain: this.domain,
            secure: this.secure
        });
    };
    
    CustomAuthStorage.prototype.clear = function clear() {
        let cookies = Cookies.get();
        let index = void 0;
        for(index = 0; index < cookies.length; ++index) {
            Cookies.remove(cookies[index]);
        }
        return {};
    };
    
    return CustomAuthStorage;
})();
