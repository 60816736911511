import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import App from "../components";
import { signIn } from "../../actions-index";

const mapStateToProps = (state) => {
    const { data = {}, status = "" } = state.user;

    return {
        user: {
            ...data,
            status
        }
    };
};




const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (redirect) => dispatch(signIn(redirect))
    };
};
               // withRouter(connect(mapStateToProps, mapDispatchToProps)(Review));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

