import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline, Container } from "@material-ui/core";
import { responsiveFontSizes } from "@material-ui/core/styles";
import aws_exports from "../../aws-exports";
import apiConfig from "../../api-config";
import CustomAuthStorage from "../../util/CustomAuthStorage";
import NavBar from "../../Navbar/components";
import Routes from "./Routes";
import theme from "../styles/theme";
import "../styles/index.css";
import Footer from '../../Footer/components';

Auth.configure({ storage: new CustomAuthStorage(apiConfig.customAuthStorage) });
Amplify.configure({ ...aws_exports, ...apiConfig });

export const AppContext = React.createContext();
export const AppContextConsumer = AppContext.Consumer;
export default function App(props) {
  const [navHeight, setNavHeight] = useState();
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const contextProviderValue = {
    elevation: { standard: 1, high: 2, navigation: 4 },
    navHeight,
    setNavHeight: (height) => !!height && height !== navHeight && setNavHeight(height),
    screenSize,
    signIn: (redirect = false) => { props.signIn(redirect) },
    signOut: () => Auth.signOut(),
    user: props.user
  };
  
  const containerEl = useRef(null);
  const location = useLocation();
  useEffect(() => {
    window.addEventListener("resize", () => setScreenSize(window.innerWidth));
    if (props.user.status === "" && location.pathname !== "/signout") props.signIn();
    containerEl.current && containerEl.current.focus();
  });


  return (
    <AppContext.Provider value={contextProviderValue}>
      <ThemeProvider theme={responsiveFontSizes(theme(false, contextProviderValue.elevation))}>
        <div className="App">
          <CssBaseline />

          <a id="skipToMain" href="#main" className="sr-only sr-only-focusable">Skip to main content</a>
          <NavBar className="mb-2" />

          <Container id="main" component="main" className="container" ref={containerEl} tabIndex="-1">
            <Routes pathname={location.pathname} />
          </Container>

          <br clear="all" />

        </div>

        <Footer />

      </ThemeProvider>

    </AppContext.Provider>
  );
}