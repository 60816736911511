import { rootDarkColor } from "../../styles/palette";

const elevations = {
    elevation0: {
        backgroundColor: rootDarkColor
    },
    elevation1: {
        backgroundColor: "#1e1e1e"
    },
    elevation2: {
        backgroundColor: "#232323"
    },
    elevation3: {
        backgroundColor: "#252525"
    },
    elevation4: {
        backgroundColor: "#272727"
    },
    elevation5: {
        backgroundColor: "#2c2c2c"
    },
    elevation6: {
        backgroundColor: "#2f2f2f"
    },
    elevation7: {
        backgroundColor: "#333333"
    },
    elevation8: {
        backgroundColor: "#353535"
    },
    elevation9: {
        backgroundColor: "#383838"
    },
    elevation10: {
        backgroundColor: "#3a3a3a"
    },
    elevation11: {
        backgroundColor: "#3d3d3d"
    },
    elevation12: {
        backgroundColor: "#3f3f3f"
    },
    elevation13: {
        backgroundColor: "#414141"
    },
    elevation14: {
        backgroundColor: "#444444"
    },
    elevation15: {
        backgroundColor: "#464646"
    },
    elevation16: {
        backgroundColor: "#494949"
    },
    elevation17: {
        backgroundColor: "#4b4b4b"
    },
    elevation18: {
        backgroundColor: "#4d4d4d"
    },
    elevation19: {
        backgroundColor: "#4f4f4f"
    },
    elevation20: {
        backgroundColor: "#525252"
    },
    elevation21: {
        backgroundColor: "#545454"
    },
    elevation22: {
        backgroundColor: "#575757"
    },
    elevation23: {
        backgroundColor: "#5a5a5a"
    },
    elevation24: {
        backgroundColor: "#5b5b5b"
    }
}

export default elevations