import Moment from "moment";

export function formatDateString(dateString) {
    const date = Moment(dateString);
    return date.isValid() ? date.valueOf() : "";
}

export function formatText(text) {
    return text.toLowerCase(); // fixes a sort issue with text that starts with a lowercase letter
}
