import { CONTENT, CONTENT_FILTER, CONTENT_STATUS} from "../actions-index";
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ERROR_NO_CURRENT_USER,ACTION_STATUS_SUCCESS } from "../util/constants";
import { buildAction, redirectToSignIn } from "../util/util";
import { API, Auth } from 'aws-amplify';


export function getContent() {
    return dispatch => {
        dispatch(buildAction(CONTENT_STATUS, ACTION_STATUS_LOADING));
        Auth.currentSession()
            .then(() => {
                API.get(process.env.REACT_APP_DIGITAL_HOME_API_NAME, "/taxdocument")
                    .then(result => {
                        dispatch(buildAction(CONTENT_STATUS, ACTION_STATUS_SUCCESS));
                        dispatch(buildAction(CONTENT, result));
                        return result
            
                    });
            })
            .catch(error => {
                if(error === ERROR_NO_CURRENT_USER) {
                    redirectToSignIn();
                } else {
                    dispatch(buildAction(CONTENT_STATUS, ACTION_STATUS_ERROR));
                }
            });
    };
}


export function filterContent(field, value) {
    return dispatch => dispatch(buildAction(CONTENT_FILTER, { field, value }));
}
