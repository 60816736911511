import React, { Component, Fragment } from 'react'
import '../style/index.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { chunk, find, isEmpty, orderBy } from 'lodash';
import {
    OSUButton,
    Typography,
    Icon,
    Body1,
    Subtitle2
} from 'osu-react-components';
import { typeVariants } from '../enums';

class Table extends Component {
    constructor() {
        super();
        this.state = {
            showContent: false,
            expandingTriggered: false,
            keyPressed: null,
            sortBy: {
                key: null,
                direction: 'asc'
            },
            rowsOpen: []
        }
    }

    componentDidMount() {
        if (this.props.sortable && Array.isArray(this.props.dataKeys) && this.props.dataKeys.length > 0) {
            const sortColumn = this.props.defaultSortColumn ? this.props.defaultSortColumn : 0;

            this.sortData(this.props.dataKeys[sortColumn].key, this.props.defaultSortDirection)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.length !== this.props.data.length) {
            this.setState({ showContent: false, rowsOpen: [] })
        }
    }

    dataHeaders(dataKeys, rowHeight, sortable) {
        const { sortBy } = this.state

        return (
            <thead>
                <tr data-testid={`header`} className={`${this.props.border ? 'header-border-bottom' : ''}`}>
                    {dataKeys.map((col, index) => {
                        const currentlySortedBy = sortBy.key === col.key
                        const { sortKeys } = this.props
                        const allowedKey = sortKeys && Array.isArray(sortKeys) ? sortKeys.includes(col.key) : sortable

                        const simpleHeader = <Typography variant={this.props.headerVariant}>{col.label}</Typography>

                        const sortableHeader = (
                            <Fragment>
                                <OSUButton ariaLabel={`${col.label}`} ariaDescribedBy={`h-col${index}ButtonDesc`} link className="p-0" uppercase={false} color="black" onClick={() => this.sortData(col.key)} variant={this.props.headerVariant}>
                                    {col.label}
                                    {currentlySortedBy ? <Icon key={col.key + '-sort-icon'} className="pl-2 align-self-end" type={sortBy.direction === 'desc' ? 'sort-down' : 'sort-up'} /> : null}
                                </OSUButton>
                                <span id={`h-col${index}ButtonDesc`} className="sr-only" aria-hidden="true">Click to sort</span>
                            </Fragment>
                        );
                        const ariaSort = (currentlySortedBy ? (sortBy.direction === "desc" ? "descending" : "ascending") : "none");
                        return <th scope="col" data-testid={`h-col${index}`} className={`py-${rowHeight || 3} pr-2${col.hasOwnProperty('className') ? ` ${col.className}` : ''}`} key={"header" + col.key} aria-sort={ariaSort}>
                            {!sortable || !allowedKey ? simpleHeader : sortableHeader}
                        </th>
                    })}
                    {!!this.props.expandable && <Icon color='blue' className="btn mb-2 mt-2 py-2 invisible align-self-center" type={`chevron-up`} />}
                </tr>
            </thead>
        );
    }

    sortData(sortKey, direction = null) {
        this.setState((state, props) => {
            const { sortBy } = state
            const defaultSortKey = sortBy.key
            const sortDirection = direction ? direction : defaultSortKey === sortKey && sortBy.direction === 'asc' ? 'desc' : 'asc'

            return {
                sortBy: {
                    key: sortKey,
                    direction: sortDirection
                }
            }
        })
    }

    executeAction(values) {
        const { index, action = null, actionAllowed = false } = values

        if (!!actionAllowed) {
            !!action && typeof action === 'function' && action(index)
            this.props.multiOpen && this.setState((state) => {
                return {
                    rowsOpen: state.rowsOpen.concat([index])
                }
            })
        } else if (this.props.multiOpen) {
            this.setState((state) => {
                return { rowsOpen: state.rowsOpen.filter(row => row !== index) }
            })
        }
    }

    showContent(values) {
        const { index } = values

        this.executeAction(values)

        this.setState({
            expandingTriggered: true
        })
        if (this.state.openContentIndex !== index && this.state.showContent) {
            this.setState({
                openContentIndex: index,
            })
        } else {
            this.setState((state, props) => {
                return {
                    showContent: !state.showContent,
                    openContentIndex: index
                }
            });
        }
    }

    collapseRowsIfPageHasChanged() {
        if (this.props.paginationData) {
            if (this.state.dataIndex !== this.props.paginationData.dataIndex) {
                this.setState({
                    dataIndex: this.props.paginationData.dataIndex
                })
                return true;
            }
        }
        return false;
    }

    evaluateDataToDisplayOnCurrentPage(paginationData, rowData) {
        const dataIsPaginated = !!paginationData.rowsPerPage && (!!paginationData.dataIndex || paginationData.dataIndex === 0);
        const paginatedData = chunk(rowData, paginationData.rowsPerPage)[paginationData.dataIndex];
        const dataIsPresent = dataIsPaginated ? paginatedData && paginatedData.length > 0 : rowData.length > 0;
        return dataIsPresent ? (dataIsPaginated ? paginatedData : rowData) : [];
    }

    dataRows(values) {
        const { data, dataKeys, border, hover, className } = values
        const paginationData = this.props.paginationData ? this.props.paginationData : {};
        const { rowsOpen } = this.state
        const displayedData = this.evaluateDataToDisplayOnCurrentPage(paginationData, data);

        return displayedData.map((item, index) => {
            const singleOpen = !this.props.multiOpen && !!this.props.expandable && this.state.showContent && this.state.openContentIndex === index && item.hasOwnProperty('expandedContent')
            const multiOpen = rowsOpen && Array.isArray(rowsOpen) && rowsOpen.includes(index)
            const rowClass = `${!!this.props.alignCenter ? ' align-items-center' : ''}${!!border ? ' table-border-bottom' : ''}${hover ? ' table-row-hover' : ''}`
            let reactKey = index

            const tableRow = (key, onRowClick = null, testId = null) => {
                const { rowHeight } = this.props
                const onClickEvent = (actionAllowed) => !!this.props.expandable
                    ? this.showContent({
                        index,
                        action: item.hasOwnProperty('innerExpandedAction') ? item.innerExpandedAction : null,
                        updateRowIndex: item.hasOwnProperty('updateRowIndex') ? item.updateRowIndex : null,
                        actionAllowed
                    })
                    : null
                const chevron = (direction = '') => !!this.props.expandable ? <OSUButton ariaLabel={direction === 'up' ? 'Expand row to see content' : 'Close row to hide content'} className="mb-2 mt-2 py-2" link onClick={() => onClickEvent(direction === 'up')}><Icon color='blue' className="align-self-center" type={`chevron-${direction}`} /></OSUButton> : null
                const columns = dataKeys.map((dataKey, index) => {
                    const styles = { }
                    if(dataKey.width && !isNaN(dataKey.width)) styles.width = `${dataKey.width}%`;
                    const keyIdentifier = reactKey + index + dataKey.key
                    let content = item[dataKey['key']]
                    if (typeof content === 'string') {
                        content = <Body1 color="black">{content}</Body1>
                    }
                    const dataCol = (widthStyles, keyIdentifier) => <td data-header={dataKey.label} data-testid={`column${index}`} style={widthStyles} key={keyIdentifier} className={`py-${rowHeight || 3} pr-2 ${dataKey.hasOwnProperty('className') ? `${dataKey.className} ` : ''}`}> {content} </td>
                    return dataCol(styles, keyIdentifier)
                })
                if (!!this.props.expandable) {
                    return <tr data-testid={testId} className={`${rowClass}`} key={`${key}${index}`}>
                        <div className={`w-100 align-items-center`} onClick={!!onRowClick ? () => onRowClick() : null}>
                            {columns}
                        </div>
                        {chevron(singleOpen || multiOpen ? 'down' : 'up')}
                    </tr>
                }
                return <tr data-testid={testId} role={!!onRowClick ? 'button' : null} onFocus={!!onRowClick ? () => this.setState({ keyPressed: key }) : null} key={`${key}${index}`} className={`${rowClass}`} onClick={!!onRowClick ? () => onRowClick() : null}>
                    {columns}
                </tr>
            }

            if (singleOpen || multiOpen) {
                return [
                    tableRow(reactKey + '--row', item.hasOwnProperty('onRowClick') ? item.onRowClick : null, `row${index}`),
                    <div key={`${reactKey}-expandable-row-expanded-content`} className={`${className ? className + ' ' : ''}expanded-content`}>{item.expandedContent}</div>
                ]
            }
            if (item.hasOwnProperty('onRowClick')) {
                return tableRow(reactKey, item.onRowClick, `row${index}`)
            }
            if (!isEmpty(item.rowPath)) {
                return <Link data-testid={`row${index}`} className={`table-row-link`} key={`${reactKey}-linked-path`} to={item.rowPath}>{tableRow()}</Link>
            }
            return tableRow(reactKey, null, `row${index}`)
        })
    }

    buildMultirowexpansionState(data) {
        if (this.props.expandAll && this.state.rowsOpen.length !== data.length) {
            const allRowsOpen = Array.from(Array(data.length).keys());
            this.setState((state) => {
                return { rowsOpen: allRowsOpen }
            })
        }

        if ((this.props.collapseAll && this.state.rowsOpen.length !== 0) || this.collapseRowsIfPageHasChanged()) {

            const noRowsOpen = []
            this.setState((state) => {
                return { rowsOpen: noRowsOpen }
            })
        }
    }


    renderTableBody(values) {
        const { expandable, data, dataKeys, border, hover } = values

        if ((isEmpty(data) && isEmpty(dataKeys)) || !Array.isArray(data) || !Array.isArray(dataKeys)) {
            return null
        }
        if (!!expandable) {
            return (
                <tbody>
                    {this.dataRows({ data, dataKeys, border, hover })}
                </tbody>
            );
        }
        return (
            <tbody>
                {this.dataRows({ data, dataKeys, border, hover })}
            </tbody>
        );
    }

    render() {

        const { id, data, dataKeys, border, hover, className, nested, expandable, noDataMessage, rowHeight, sortable } = this.props
        const { sortBy } = this.state
        const dataPropsInvalid = isEmpty(data) || !Array.isArray(data) || isEmpty(dataKeys) || !Array.isArray(dataKeys)
        let tableData = data
        if (sortable && !dataPropsInvalid && sortBy.key) {
            tableData = orderBy(data, item => {
                let val = item[sortBy.key];
                if (React.isValidElement(val) && val.props && val.props.children) {
                    val = val.props.children;
                }
                let dataKey = find(dataKeys, ["key", sortBy.key]);
                if (dataKey && typeof dataKey.sortFormatter === "function") {
                    val = dataKey.sortFormatter(val);
                }
                return val;
            }, sortBy.direction)
        }

        this.buildMultirowexpansionState(data);

        if (!!nested) {
            return !dataPropsInvalid ? this.dataRows({ data: tableData, dataKeys, border, hover, className })
                : <Subtitle2 dataTestId="no-data-message-nested" className="py-2 text-center highlight">{noDataMessage}</Subtitle2>
        }

        return <table id={id} data-testid={this.props.dataTestId} className={`custom-table w-100 ${className ? className + ' ' : ''}`}>
            { this.props.caption && <caption className="caption" align="top"><strong>{ this.props.caption }</strong></caption> }
            {!this.props.noHeader && !!Array.isArray(dataKeys) && this.dataHeaders(dataKeys, rowHeight, sortable)}
            {dataPropsInvalid ?
                noDataMessage && noDataMessage.length > 0 && <Subtitle2 dataTestId="no-data-message" className="mt-2 text-center highlight">{noDataMessage}</Subtitle2>
                : this.renderTableBody({ expandable, data: tableData, dataKeys, border, hover })
            }
        </table>
    }
}

Table.defaultProps = {
    hover: true,
    headerVariant: 'heading6',
    expandable: false,
    expandAll: false,
    collapseAll: false,
    noHeader: false,
    nested: false,
    border: true,
    alignCenter: true,
    dataTestId: "osu-table",
    noDataMessage: 'No data found',
    rowHeight: 3,
    sortable: false,
    defaultSortColumn: 0,
    defaultSortDirection: "asc"
}

const verifyValidDataKeys = createVerifyValidDataKeys(false);
verifyValidDataKeys.isRequired = createVerifyValidDataKeys(true);
const tableIsExpandable = requireExpandableFields();

Table.propTypes = {
    id: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
        innerExpandedAction: PropTypes.func,
        expandedContent: PropTypes.element,
        onRowClick: PropTypes.func,
        rowPath: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    })).isRequired,
    paginationData: PropTypes.shape({
        dataIndex: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired
    }),
    dataKeys: verifyValidDataKeys.isRequired,
    dataTestId: PropTypes.string,
    expandedContent: tableIsExpandable,
    expandAll: PropTypes.bool,
    collapseAll: PropTypes.bool,
    onRowClick: PropTypes.func,
    className: PropTypes.string,
    headerVariant: PropTypes.oneOf(typeVariants),
    rowHeight: PropTypes.oneOf([0, 1, 2, 3, 4, 5, '0', '1', '2', '3', '4', '5']),
    sortable: PropTypes.bool,
    sortKeys: PropTypes.arrayOf(PropTypes.string),
    defaultSortKey: PropTypes.string,
    defaultSortColumn: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]),
    defaultSortDirection: PropTypes.string
}

function createVerifyValidDataKeys(isRequired) {
    return function (props, propName, componentName) {
        const prop = props[propName]
        if (typeof prop === 'undefined' || prop === null) {
            if (isRequired) {
                throw new Error(`Prop ${propName} of ${componentName} is required`);
            }
            return
        }

        let error = null;
        prop.forEach(child => {
            if (typeof child !== 'object') {
                error = new Error(`${propName} of ${componentName} must be of type object.`);
            } else {
                let messages = [];
                if (!child.hasOwnProperty('key')) {
                    messages.push(`missing property 'key' in ${componentName}`)
                }
                if ((!child.hasOwnProperty('label')) && !props.noHeader) {
                    messages.push(`missing property 'label' in ${componentName}`)
                }
                if (!child.hasOwnProperty('width')) {
                    messages.push(`missing property 'width' in ${componentName}`)
                } else if (typeof child.width !== 'number') {
                    messages.push(`missing valid number for 'width' in ${componentName}`)
                } else {
                    const sumOfWidths = prop.map(child => child.width).reduce(function (a, b) { return a + b; }, 0);
                    if (Math.round(sumOfWidths) !== 100) {
                        error = new Error(`Please make sure your widths are valid numbers that equal 100.`);
                    }
                }

                if (messages.length > 0) {
                    error = new Error(`${propName} of ${componentName} is ${messages.map(message => ' ' + message + ' ')}`);
                }
            }
        })
        return error
    }
}

function requireExpandableFields() {
    return function (props, propName) {
        if (!props.expandable) {
            return
        }
        if (!!props.path) {
            throw new Error(`To properly create a expandable table, a path cannot exist.`);
        }
        const prop = props[propName]

        if (propName === 'expandedContent' && !React.isValidElement(prop)) {
            if (props.hasOwnProperty('data') && props.data.filter(item => item.expandedContent && React.isValidElement(item.expandedContent)).length > 0) {
                if (props.data.filter(item => !isEmpty(item.rowPath)).length > 0) {
                    throw new Error(`To properly create a expandable table, row path cannot be defined, along with expanded content.`);
                }
                return
            }
            throw new Error(`To properly create a expandable table, an element prop for expandedContent must be included, or expandedContent must be passed in to each data item.`);
        }
    }
}

export default Table